import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Buttom from '../components/Button'
import { sendEmail } from '../services/contact'

const ContactForm = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState({ error: false, content: null })
  const form = useRef(null)

  const resetForm = () => {
    form.current.reset()
  }

  const handleSubmission = async (e) => {
    e.preventDefault()
    const { elements } = e.target

    const name = elements.name.value
    const email = elements.email.value
    const subject = elements.subject.value
    const message = elements.message.value

    setLoading(true)
    setMessage({
      error: false,
      content: null
    })

    try {
      await sendEmail({
        name,
        email,
        subject,
        message,
      })

      setMessage({
        error: false,
        content: t('contactForm.successMessage')
      })

      resetForm()
    } catch (e) {
      setMessage({
        error: true,
        content: t('contactForm.failMessage')
      })

      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <form
      ref={form}
      onSubmit={handleSubmission}
      className="border border-green-theme bg-white"
      autoComplete="off"
    >
      <fieldset
        className="grid grid-cols-1 lg:grid-cols-2 gap-x-6 gap-y-8 px-5 xl:px-32 py-12 m-2 xl:m-0 disabled:opacity-50"
        disabled={loading}
      >
        <h1 className="lg:col-span-2 text-center text-3xl xl:text-5xl font-bold mb-5">
          {t('contact us')}
        </h1>

        {message.content ? (
          message.error ? (
            <div className="col-span-2 bg-red-400 py-2 px-4 flex gap-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
              <div>{message.content}</div>
            </div>
          ) : (
            <div className="col-span-2 bg-green-400 py-2 px-4 flex gap-x-2">
              <div>{message.content}</div>
            </div>
          )
        ) : null}

        <div>
          <input
            className="form-input w-full border border-green-theme"
            name="name"
            type="text"
            placeholder={t('contactForm.namePlaceHolder')}
          />
        </div>
        <div>
          <input
            className="form-input w-full border border-green-theme"
            name="email"
            type="email"
            placeholder={t('contactForm.emailPlaceHolder')}
          />
        </div>

        <div className="lg:col-span-2">
          <input
            className="form-input w-full border border-green-theme"
            name="subject"
            type="text"
            placeholder={t('contactForm.subjectPlaceHolder')}
          />
        </div>

        <div className="lg:col-span-2">
          <textarea
            className="form-textarea w-full border border-green-theme"
            name="message"
            cols="30"
            rows="8"
            placeholder={t('contactForm.messagePlaceHolder')}
          ></textarea>
        </div>

        <div className="lg:col-span-2 flex justify-center">
          {!loading ? (
            <Buttom>{t('send')}</Buttom>
          ) : (
            <Buttom className="flex gap-x-2">
              {t('send')}
              <FontAwesomeIcon
                icon={['fa', 'fa-spinner']}
                className="fa-pulse"
              />
            </Buttom>
          )}
        </div>
      </fieldset>
    </form>
  )
}

export default ContactForm
