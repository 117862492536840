export const sendEmail = ({ name, email, subject, message }) => {
  const url = process.env.GATSBY_GETFROM_URL
  const formData = new FormData()

  formData.append('name', name)
  formData.append('email', email)
  formData.append('subject', subject)
  formData.append('subject', message)

  return fetch(url, {
    method: 'POST',
    body: formData,
  })
}
