import React from 'react'
import DefaultLayout from '../layouts/DefaultLayout'
import ContactForm from '../components/ContactForm'
import MapImage from '../assets/map.png'

const ContactUsPage = () => {
  return (
    <DefaultLayout>
      <div className="hidden xl:block h-96 relative z-0">
        <img className="w-full h-full object-cover" src={MapImage} alt="" />
      </div>
      <div className="w-full max-w-6xl mx-auto mt-24 xl:-mt-32 relative z-10">
        <ContactForm />
      </div>
    </DefaultLayout>
  )
}

export default ContactUsPage
